const fontFamily = "Nunito"

const style = {
  fontFamily
}

const colors = ["#003026", "#006646", "#00B687", "#B8D0EB", "#B95F89", "#E49D9D"]

// Function to convert hex color to RGB
const hexToRgb = hex => {
  const red = parseInt(hex.slice(1, 3), 16),
        green = parseInt(hex.slice(3, 5), 16),
        blue = parseInt(hex.slice(5, 7), 16)

  return [red, green, blue]
}

// Function to convert RGB color to hex
const rgbToHex = (red, green, blue) => {
  return "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)
}

// Function to generate color variants
const generateColorVariants = (baseColors, numberOfColorVariants) => {
  const iterations = Math.ceil(numberOfColorVariants / (baseColors.length * 2))

  const lighterVariants = []
  const darkerVariants  = []

  for (let iterator = 1; iterator <= iterations; iterator++) {
    const variation = iterator * (Math.floor(Math.random() * 10) + 1)
    baseColors.forEach(color => {
      const [red, green, blue] = hexToRgb(color)

      const lighter = rgbToHex(
        Math.min(red + variation, 255),
        Math.min(green + variation, 255),
        Math.min(blue + variation, 255)
      )

      const darker = rgbToHex(
        Math.max(red - variation, 0),
        Math.max(green - variation, 0),
        Math.max(blue - variation, 0)
      )

      lighterVariants.push(lighter)
      darkerVariants.push(darker)
    })
  }
  return [
    ...baseColors,
    ...lighterVariants,
    ...darkerVariants
  ]
}

export const getBarChartOptions = (categories, numberOfColorVariants = 0, horizontal = false) => ({
  colors     : generateColorVariants(colors, numberOfColorVariants),
  plotOptions: {
    bar: {
      horizontal,
      borderRadius: 4
    }
  },
  chart: {
    legend : "show",
    stacked: true,
    toolbar: {
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories,
    labels: {
      style
    }
  },
  yaxis: {
    labels: {
      style
    }
  },
  legend: {
    fontFamily,
    position       : "bottom",
    height         : 50,
    horizontalAlign: "left",
    itemMargin     : {
      horizontal: 15
    }
  },
  tooltip: {
    style
  }
})

export const getHorizontalBarChartHeight = xAxisItemsLength => xAxisItemsLength * window.innerWidth / 40